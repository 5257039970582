import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const routes = [
	{
		path: '/', component: () => import("../layout/layout-main"),
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import("../views/Home"),
				meta: {api: "HomeController"}
			},
			{
				path: '/#shop',
				name: 'home-shop',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'shop'}
			},
			{
				path: '/#story',
				name: 'home-story',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'story'}
			},
			{
				path: '/#flavours',
				name: 'home-flavours',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'flavours'}
			},
			/*{
				path: '/flavours',
				name: 'flavours',
				component: () => import("../views/Flavours"),
				meta: {api: 'FlavoursController'}
			},*/
			{
				path: '/#corporate',
				name: 'home-corporate',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'corporate'}
			},
			{
				path: '/#stores',
				name: 'home-stores',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'stores'}
			},
			{
				path: '/#partnership',
				name: 'home-partnership',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'partnership'}
			},
			{
				path: '/#contacts',
				name: 'home-contacts',
				component: () => import("../views/Home"),
				meta: {api: 'HomeController', hash: 'contacts'}
			},

			{
				path: '/shop',
				name: 'shop',
				component: () => import("../views/Shop"),
				meta: {api: "CatalogController"}
			},
			{
				path: '/shop/:code',
				component: () => import("../layout/layout-category"),
				children: [
					{
						path: '/shop/:code',
						name: 'category',
						props: true,
						component: () => import("../views/Category"),
						meta: {api: "CategoryController", routingLoadingId: "category",}
					},
					{
						path: '/shop/:sectionCode/:code',
						//alias: '/shop/:sectionCode/:code/:type',
						name: 'product',
						props: true,
						component: () => import("../views/Product"),
						meta: {api: "ProductController", routingLoadingId: "category"}
					},
					/*{
						path: '/shop/:sectionCode/:code/:type',
						name: 'product',
						props: true,
						component: () => import("../views/Product"),
						meta: {api: "ProductController"}
					},*/
				]
			},
			{
				path: '/box',
				name: "box",
				props: true,
				component: () => import("../views/Category"),
				meta: {api: "CategoryController"}
			},
			{
				path: '/flavours/:code',
				name: 'flavour',
				component: () => import("../views/Flavour"),
				meta: {api: "FlavourController"}
				/*component: () => import("../views/Flavours"),
				children: [
					{
						path: '/flavours',
						name: 'flavours-category',
						component: FlavoursCategory,
					},
					{
						path: '/flavours/original',
						name: 'flavours-original',
						component: FlavoursOriginal,
					},
					{
						path: '/flavours/original/praline',
						name: 'praline',
						component: Praline,
					},
				]*/
			},
			{
				path: '/terms',
				name: 'terms',
				component: () => import("../views/Terms"),
				meta: {api: "TermsController"}
			},
			{
				path: '/basket',
				name: 'basket',
				component: () => import("../views/Basket"),
				meta: {api: "BasketController"}
			},
			{
				path: '/order/:id',
				name: 'order',
				component: () => import('@/views/Order'),
				meta: {
					api: "OrderController",
					isHeaderInversed: true,
					routingLoadingId: "order",
					withoutLoading: true	// очень долгий - не затухаем todo придумать красивее
				}
			},
			{
				path: '/order/status/:hash',
				name: 'order-status',
				component: () => import('@/views/OrderStatus'),
				meta: {api: "OrderStatusController", isHeaderInversed: true, routingLoadingId: "order"}
			},
			{
				path: '/policy',
				name: 'policy',
				component: () => import("../views/Policy"),
				meta: {api: "PolicyController"}
			},
			{
				path: '/error404',
				name: 'error404',
				component: () => import("../views/Error404"),
				meta: { api: "PageController", code: "error404" }
			},
			{
				path: '/error500',
				name: 'error500',
				component: () => import("../views/Error500"),
				meta: { api: "PageController", code: "error500" }
			},
			{
				path: '/under-construction',
				name: 'under',
				component: () => import("../views/UnderConstruction"),
				meta: { api: "PageController", code: "under-construction" }
			},
			{
				path: '*',
				//name: 'error404',
				component: () => import("../views/Error404"),
			},

		]
	}
];
export default routes;