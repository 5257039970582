<template>
	<v-app>
		<transition name="route" mode="out-in">
			<router-view/>
		</transition>

		<v-snackbar
			v-if="snackbarText"
			class="custom-snackbar"
			v-model="snackbar"
			timeout="-1"
			outlined
			color="#C09C5E"
			width="410px"
		>
			<div>{{ snackbarText }}</div>
			<template v-slot:action="{ attrs }">
				<svgicon name="cross" @click="snackbar=false"/>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import config from "@/config";
import store from "@/store";

export default {
	name: 'App',
	data: () => ({
		swiperReinitCount: 0,
		snackbar: false,
	}),
	computed: {
		isSaleOn() {
			return config.IS_SALE_ON
		},
		isWindowMD() {
			return this.$store.getters.isWindowMD;
		},
		texts() {
			return this.$store.state.common.texts;
		},
		snackbarText() {
			return this.texts?.POPUP?.TEXT_1;
		}
	},
	methods: {
		/*onScroll() {
			console.log("SCROLL", document.documentElement.scrollTop);
		},*/
		onResize() {
			//console.log("App onResize");

			this.$store.commit('updateWindowWidth');
			this.$store.commit('updateWindowHeight');

			// на iOS чтобы попадало во весь экран на телефоне 100vh:
			//нужно добавить css переменную vh в main.scss:
			//:root {
			//--vh: 1vh;
			//}
			//после вместо vh использовать var(--vh, 1vh)
			//например всето 100vh = calc(var(--vh, 1vh)*100)
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			//this.reinitSwipers();

			if (this.isWindowMD) {
				this.$store.state.withBurgerMenu = false;
				this.$store.state.withBasketPopup = false;
			}
		},
		/*reinitSwipers() {

			/!*
				TODO важно! уродский свафпер не пересоздается при ресайзе/уничтожении в v-if
				TODO приходится его полностью пересоздавать (точнее обновлять его ref)
				https://github.com/surmon-china/vue-awesome-swiper/issues/774
				https://github.com/surmon-china/vue-awesome-swiper/issues/687
			*!/
			this.swiperReinitCount++;   // это важно!

			setTimeout(() => {
				// обновим референс на свайпер для доступа снаружи
				// TODO пройти по всем свайперам на странице и реинитить их
				//this.$store.state.appSwiper = this.swiper;
				//console.log("APP SWIPER reinited", this.swiper);
				let keys = Object.keys(this.$store.state.swipers);
				keys.forEach(key => {
					if (this.$store.state.swipers[key]) this.$store.state.swipers[key].update();
				});
			}, 300);
		}*/
		runSessionRefresh() {
			this.$store.dispatch('fetchSession').then(() => {
				console.log("Session refreshed");
			})
				.catch(() => {
				})
				.finally(() => {
					setTimeout(() => {
						this.runSessionRefresh();
					}, config.SESSION_REFRESH_TIMEOUT);
				});
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
		if (!this.isSaleOn) {
			this.snackbar = true;
		}

		setTimeout(() => {
			this.runSessionRefresh();
		}, config.SESSION_REFRESH_TIMEOUT);
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	}
};
</script>

<style lang="scss">

#app {
	//overflow-x: hidden;	commented in order .sticky to work
	min-width: 320px;

	.custom-snackbar {
		.v-snack__action {
			padding-top: 14px;
			margin-right: 16px;
			align-self: start;
			cursor: pointer;

			&:hover {
				svg {
					opacity: 0.8;
				}
			}

			svg {
				@include transition();
				width: 12px;
				height: 12px;
				stroke: #E1E5EC;
			}
		}
	}
}

</style>
