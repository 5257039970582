import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import routes from '@/router/routes';
import {mergeObjects} from "@/utils/utils";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    /*scrollBehavior(to, from, savedPosition) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition);
                } else {
                    resolve({ top: 0, left: 0 });
                }
            }, 500);
        })
        //console.log("savedPosition", savedPosition);
    },*/
});

const scrollTo = (hash = null, offset = 0, behavior = 'smooth') => {
    let top = 0;

    if (typeof hash === "string") {
        hash = hash.match(/^\#.+/) ? hash.substring(1) : hash;
        const el = document.getElementById(hash);
        //console.log('Rect:', el.getBoundingClientRect());
        if (el) top = el.getBoundingClientRect().top + window.scrollY + offset;
        else {
            console.warn("Scroll to element not found: " + hash);
            return;
        }
    } else if (typeof hash === "number") top = hash;

    //console.log("Scrolling to", top);
    window.scrollTo({
        behavior,
        left: 0,
        top
    });
};

window.isPopState = false;
window.addEventListener('popstate', () => {
    window.isPopState = true;
});

let lastPagePos;
router.beforeEach((to, from, next) => {
    if (to.meta?.forced) {  // todo still used?
        console.log("FORCED");
        return;
    }

    // save page position
    if (!window.isPopState) lastPagePos = document.documentElement.scrollTop || document.body.scrollTop;

    // если мы навигируем только по hash-ам на том же роуте - просто скроллим
    const fromPath = from.path.replace(/^(.*?)(#.+)/, "$1");
    const toPath = to.path.replace(/^(.*?)(#.+)/, "$1");
    if (fromPath === toPath && (to.meta?.hash || from.meta?.hash)) {
        if (to.meta.hash) scrollTo(to.meta.hash);
        else scrollTo(null, 0,);  // clicked over logo - move to top
        store.state.withBurgerMenu = false;
        next();
        return;
    }

    //if (from.meta?.isInternal && to.meta?.isInternal) store.state.isInternalLoading = true;
    if (!to.meta?.withoutLoading) {
        store.state.isLoading = from.meta?.routingLoadingId !== to.meta?.routingLoadingId || typeof to.meta?.routingLoadingId === "undefined";
        store.state.routingLoadingId = to.meta?.routingLoadingId;
    }

    // we need to fetch (1) user & (2) page data
    const ps = [];

    // 1. загружаем данные юзаера (некэшируемые), они не зависят от роута:
    ps.push(new Promise((resolve, reject) => {
        store.dispatch('fetchSession').then(() => {
            // user data fetched
            // здесь не вызываем next() - его вызовем ниже после получения обои запросов
            //console.log("Session fetched");
            resolve();
        }).catch(() => {
            console.warn("User data is empty...");

            // todo
            //reject();
            resolve();
        });
    }));

    // 2. загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
    if (to.meta?.api) {
        ps.push(new Promise((resolve, reject) => {
            let p = null;
            if (to.meta.api === 'PageController') {
                p = mergeObjects({}, to.params, to.query, { code: to.meta.code });
            } else {
                p = mergeObjects({}, to.params, to.query)
            }
            let params = mergeObjects({}, {
                action: to.meta.api,
                method: to.meta.method,
                params: p,
            }, to.meta);
            store.dispatch('fetchPage', params)
                .then(() => {
                    // page data fetched
                    // здесь не вызываем next() - его вызовем ниже после получения обои запросов
                    //console.log("Page fetched");
                    resolve();
                })
                .catch((err) => {
                    console.error("API error: ", err);
                    reject();
                });
        }));
    }

    setTimeout(() => {
        Promise.all(ps)
            .then(values => {
                //console.log("All fetched");
                next();
            })
            .catch(err => {
                // todo

                /*if (err.code === Config.API_EXCEPTION_NOT_FOUND) {
                  router.replace({name: 'error404'});
                } else if (err.code == Config.ERROR_CODE_FORBIDDEN || err.code == Config.API_EXCEPTION_FORBIDDEN) {
                  router.replace({name: 'login'});
                } else if (err.code === Config.API_EXCEPTION_SUSPENDED) {
                  router.replace({name: 'report-accepted'});
                } else {
                  router.replace({name: 'error500'});
                }*/
            });
    }, 250);	// чуть больше половины от transition-long() - ждем затухания перед загрузкой
});

router.afterEach((to, from) => {
    // если мы навигируем только по hash-ам на том же роуте - то мы уже проскролили
    const fromPath = from.path.replace(/^(.*?)(#.+)/, "$1");
    const toPath = to.path.replace(/^(.*?)(#.+)/, "$1");
    if (fromPath === toPath && (to.meta?.hash || from.meta?.hash)) {
        return;
    }

    //if (from.meta?.isInternal && to.meta?.isInternal) store.state.isInternalLoading = false;
    store.state.isLoading = false;
    store.state.routingLoadingId = null;

    store.state.isHeaderInversed = to.meta?.isHeaderInversed;// && !to.meta?.hash;

    setTimeout(() => {

        if (from?.path && to?.path !== from.path) {
            if (window.isPopState && lastPagePos) {
                //we are moving back - restore page position
                //console.log("lastPagePos", lastPagePos);
                // пауза нужна видимо для построения дома и подгрузки картинок - без нее не работает
                setTimeout(() => {
                    scrollTo(lastPagePos, 0, 'smooth');
                    lastPagePos = null;
                }, 500);
                window.isPopState = false;
            } else {
                const anchor = (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
                if (anchor) {
                    // yet more pause to allow dom-model to appear
                    setTimeout(() => scrollTo(anchor, -120, 'smooth'), 500);
                } else {
                    // scroll to top
                    scrollTo(null, 0, 'instant');
                }
            }
        }

        store.state.withBurgerMenu = false;

    }, 150);	// поставь 210 для ручного transition-long()
});

export default router
